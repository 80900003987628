import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConfig } from '../core/config/app.config';
import { PharHttp } from '../core/http/http-client.service';
import { IRole } from '../shared/models/role.interface';
import { IPermission } from '../shared/models/permission.interface';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class RolesAndPermissionsService {
  constructor(
    private http: PharHttp,
    private config: AppConfig,
  ) {
  }


  create(role: Partial<IRole>) {
    return this.http.post(this.config.buildUri('rolesAndPermissions') + '/CreateRole', role);
  }


  getRolesList(serverIdentifier: string): Observable<IRole[]> {
    let url = '';
    if (serverIdentifier) {
      url = `?serverIdentifier=${serverIdentifier}`
    }
    return this.http.get(this.config.buildUri('rolesAndPermissions') + '/GetAllRoles' + url);
  }

  getOnlyRolesList() {
    return this.http.get(this.config.buildUri('rolesAndPermissions') + '/GetAllRoles?excludePermissions=true')
  }

  deleteRole(id: string): Observable<any> {
    return this.http.delete(this.config.buildUri('rolesAndPermissions') + '/DeleteRole/?roleId=' + id);
  }

  getRoleById(id: string) {
    return this.http.get(this.config.buildUri('rolesAndPermissions') + '/GetRoleById?roleId=' + id);

  }

  update(role: Partial<IRole>): Observable<IRole> {
    return this.http.post(this.config.buildUri('rolesAndPermissions') + '/UpdateRole', role);
  }

  getApplications() {
    return this.http.get(this.config.buildUri('rolesAndPermissions') + '/ReadAPIs');
  }

  getStudiesWithCounter() {
    return this.http.get(this.config.buildUri('rolesAndPermissions') + '/GetAllStudies');
  }

  getPermissionsList(serverIdentifier: string): Observable<IPermission[]> {
    const url = this.config.buildUri('rolesAndPermissions') + '/GetAllPermissions';
    let httpParams = new HttpParams()
    if (serverIdentifier) {
      httpParams = httpParams.set('serverIdentifier', serverIdentifier);
    }
    return this.http.get<{
      permissions: Omit<IPermission, 'resourceServerIdentifier'>[],
      serverIdentifier?: string
    }>(url, httpParams)
      .pipe(map(({ permissions, serverIdentifier }) => {
        return permissions.map<IPermission>((p) => ({
          ...p,
          resourceServerIdentifier: serverIdentifier,
        }));
      }));
  }

  getRolePermissionsList(serverIdentifier: string, studyName?: string): Observable<IRole[]> {
    let url = this.config.buildUri('rolesAndPermissions') + '/PermissionMatrixRead';
    let httpParams = new HttpParams();
    if (studyName) {
      httpParams = httpParams.set('study', studyName)
    }

    if (serverIdentifier) {
      httpParams = httpParams.set('serverIdentifier', serverIdentifier)
    }
    return this.http.get(url, httpParams);
  }

  assignPermissionsToRole(permissions: IPermission[], role: IRole): Observable<any> {
    return this.http.post(this.config.buildUri('rolesAndPermissions') + '/AssignPermissionsToRole?roleId=' + role.id, permissions);
  }

  deletePermissionsFromRole(permissions: IPermission[], role: IRole): Observable<any> {
    return this.http.post(this.config.buildUri('rolesAndPermissions') + '/UnassignPermissionsFromRole?roleId=' + role.id, permissions);
  }


}

